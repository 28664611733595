<template>
  <div>
    <div class="list-box">
      <a-button
        type="primary"
        icon="plus"
        style="margin-bottom: 32px"
        @click="createModel()"
        >新增模块</a-button
      >
      <a-collapse v-model="activeKey">
        <a-collapse-panel
          v-for="(item, index) in list"
          :key="item.id.toString()"
        >
          <span slot="header">
            <span v-if="item.editting" @click.stop="">
              <a-input
                placeholder="模块名称"
                style="width: 200px"
                v-model="item.title"
              ></a-input
            ></span>
            <span v-else>{{ item.title }}</span>
          </span>
          <div v-if="item.editting">
            <editor class="tinymce" v-model="item.content" :init="editorInit" />
          </div>
          <div v-html="item.content" class="text-area" v-else></div>
          <div slot="extra">
            <a v-if="!item.editting" @click.stop="edit(item)"
              ><a-icon type="edit"
            /></a>
            <a-button
              type="primary"
              style="height: 22px"
              v-else
              @click.stop="complete(item)"
              >完成</a-button
            >
            <a-divider type="vertical" />
            <a @click.stop="moveUp(index)" style="height: 22px; width: 22px"
              ><a-icon type="up"
            /></a>
            <a-divider type="vertical" />
            <a @click.stop="moveDown(index)"><a-icon type="down" /></a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="确定删除此模块？"
              ok-text="删除"
              cancel-text="取消"
              @confirm="handleDelete(item)"
            >
              <a @click.stop=""><a-icon type="delete" /></a>
            </a-popconfirm>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import {
  getReadMeList,
  addReadMe,
  editReadMe,
  deleteReadMe,
  resortReadMeList,
} from "@/api/read-me";
import Vue from "vue";

import tinymce from "tinymce/tinymce.min.js";
window.tinymce.suffix = ".min";
import Editor from "@tinymce/tinymce-vue";
// 引入富文本编辑器主题的js和css
import "tinymce/themes/silver/theme.min.js";
import "tinymce/skins/ui/oxide/skin.min.css";
// 扩展插件
import "tinymce/plugins/link";
import "tinymce/plugins/code";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/toc";
import "../../../../public/tinymce/plugins/indent2em";
import "../../../../public/tinymce/plugins/lineheight";

export default {
  components: {
    Editor,
  },
  props: {},
  data() {
    return {
      list: [],
      activeKey: [],

      // tinymce的绑定值
      tinymceHtml: "",
      // tinymce的初始化配置
      editorInit: {
        selector: ".tinymce",
        language_url: "/tinymce/langs/zh_CN.js",
        language: "zh_CN",
        skin_url: "/tinymce/skins/ui/oxide",
        height: 600,
        width: "100%",
        plugins: "link code table lists wordcount toc indent2em lineheight",
        toolbar: [
          "bold italic underline strikethrough | fontsizeselect fontselect | forecolor backcolor | alignleft aligncenter alignright alignjustify lineheight| bullist numlist ",
          " formatselect toc | indent2em outdent indent blockquote | undo redo | link unlink code | removeformat",
        ],
        toc_depth: 6,
        fontsize_formats: "8pt 10pt 11pt 12pt 13pt 14pt 18pt 24pt 36pt",
        font_formats:
          "微软雅黑='微软雅黑'; 等线 = '等线';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings",
        statusbar: true, // 底部的状态栏
        menubar: true, // 最上方的菜单
        branding: false, // 水印“Powered by TinyMCE”
      },
    };
  },
  created() {
    this.loadData();
  },
  mounted() {
    tinymce.init({});
  },
  methods: {
    loadData(status = "") {
      getReadMeList().then((res) => {
        if (res.code === 0) {
          this.list = res.data;
          this.list.forEach((item) => {
            Vue.set(item, "editting", false);
          });
          if (status === "new") {
            this.edit(this.list[this.list.length - 1]);
          }
        }
      });
    },
    createModel() {
      //新增模块命名
      let newTitle = "";
      if (!this.list.find((item) => item.title === "新增模块")) {
        newTitle = "新增模块";
      } else {
        for (let i = 1; ; i++) {
          if (!this.list.find((item) => item.title === `新增模块(${i})`)) {
            newTitle = `新增模块(${i})`;
            break;
          }
        }
      }
      addReadMe({
        title: newTitle,
        content: "",
      }).then((res) => {
        if (res.code === 0) {
          this.loadData("new");
        }
      });
    },

    moveUp(index) {
      if (index === 0) {
        return;
      }
      let obj = JSON.parse(JSON.stringify(this.list[index]));
      this.list.splice(index, 1);
      this.list.splice(index - 1, 0, obj);
      this.updateOrder();
    },
    moveDown(index) {
      if (index === this.list.length - 1) {
        return;
      }
      let obj = JSON.parse(JSON.stringify(this.list[index]));
      this.list.splice(index, 1);
      this.list.splice(index + 1, 0, obj);
      this.updateOrder();
    },
    updateOrder() {
      let newOrder = [];
      this.list.forEach((item, index) => {
        newOrder.push({ id: item.id, order: index });
      });
      resortReadMeList(newOrder).then((res) => {
        if (res.code === 0) {
          this.$message.success("移动成功");
        }
      });
    },
    edit(item) {
      item.editting = true;
      this.activeKey.push(item.id.toString());
    },
    complete(item) {
      item.editting = false;
      editReadMe({
        content: item.content,
        id: item.id,
        title: item.title,
      }).then((res) => {
        if (res.code === 0) {
          this.loadData();
          this.$message.success("修改成功");
        }
      });
    },
    handleDelete(item) {
      deleteReadMe(item).then((res) => {
        if (res.code === 0) {
          this.loadData();
          this.$message.success("删除成功");
        }
      });
    },
  },
};
</script>

<style scoped>
.list-box {
  padding: 20px;
}
.text-area {
  max-height: 500px;
  overflow: auto;
}
.text-area >>> ul {
  list-style-type: disc;
}
.text-area >>> ol {
  list-style-type: decimal;
}
</style>