import request from '@/utils/request'

//使用说明
const readMeApi = {
    GetReadMeList: '/read-me/list',
    AddReadMe: '/read-me/add',
    EditReadMe: '/read-me/edit',
    DeleteReadMe: '/read-me/delete',
    ResortReadMeList:'read-me/resort'
}
  
export function getReadMeList() {
    return request({
      url: readMeApi.GetReadMeList,
      method: 'get',
    })
}
  
export function addReadMe(parameter) {
    return request({
      url: readMeApi.AddReadMe,
      method: 'post',
      data: parameter
    })
}
  
export function editReadMe(parameter) {
    return request({
      url: readMeApi.EditReadMe,
      method: 'post',
      data: parameter
    })
}

export function deleteReadMe(parameter) {
    return request({
      url: readMeApi.DeleteReadMe + `/${parameter.id}`,
      method: 'post',
    })
}
  
export function resortReadMeList(parameter) {
    return request({
      url: readMeApi.ResortReadMeList,
      method: 'post',
      data: parameter
    })
}
